import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import userApiService from '../../services/api/userApiService';
import cityApiService from '../../services/api/cityApiService';

interface AddBranchModalProps {
  selectedLocation: string;
  onSuccess: () => void; // Callback függvény a sikeres felhasználó hozzáadáshoz
}

const AddBranchModal: React.FC<AddBranchModalProps> = ({ selectedLocation, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddUser = async () => {
    try {
      // Új felhasználó objektum létrehozása
      const newBranch = {
        name: name,
        location: selectedLocation 
      };

      // API hívás a felhasználó hozzáadásához
      await cityApiService.addCity(newBranch);
      setName('');
      // Modal bezárása
      handleClose();
      onSuccess();
    } catch (error) {
      console.error('Hiba történt a felhasználó hozzáadása során:', error);
    }
  };

  return (
    <>
      {/* Felhasználó hozzáadása gomb */}
      {selectedLocation && (
        <Button variant="contained" color="secondary" onClick={handleOpen}>
          Kirendeltség hozzáadása
        </Button>
      )}

      {/* Modal ablak */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Új kirendeltség hozzáadása</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Név"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
          <Button onClick={handleAddUser} color="primary">
            Hozzáadás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddBranchModal;