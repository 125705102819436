import { DECREMENT_API_URL, INVENTORY_API_URL } from "../../constants";
import axios from "axios";
import { Inventory } from "../../components/models/inventoryModel";

// Fetch all users
const fetchInventoryById = async (id: string): Promise<Inventory[]> => {
    const response = await axios.get<{ success: boolean; data: Inventory[] }>(`${INVENTORY_API_URL}?branch_id=${id}`);
    return response.data.data;
  };

export const decrementInventoryQuantity = async (branch_id: string, item_id: string, subtype: string): Promise<void> => {
    try {
      // Make a PATCH request to decrement the quantity by 1
      const response = await axios.patch(`${DECREMENT_API_URL}`, {
        branch_id,
        item_id,
        subtype,
      });
  
      if (!response.data.success) {
        throw new Error("Failed to decrement inventory quantity.");
      }
    } catch (error) {
      console.error("Error decrementing inventory quantity:", error);
      throw error;
    }
  };
// Patch inventory to move in_transit to quantity
const moveInTransitToQuantity = async (branch_id: string, item_id: number, subtype: string): Promise<void> => {
    const response = await axios.patch(`${INVENTORY_API_URL}`, {
      branch_id,
      item_id,
      subtype,
    });
  
    if (!response.data.success) {
      throw new Error("Failed to update inventory by moving in_transit to quantity.");
    }
  };
// Export all API functions as a service
const InventoryApiService = {
    decrementInventoryQuantity,
    fetchInventoryById,
    moveInTransitToQuantity
};

export default InventoryApiService;