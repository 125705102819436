import React, { useState, useEffect } from "react";
import { User } from "../models/users";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { City } from "../models/city";
import AuthService from "../../services/AuthService";
import AddUserModal from "./AddUserModal";
import userApiService from "../../services/api/userApiService";
import cityApiService from "../../services/api/cityApiService";

const ManageUsers: React.FC = () => {
  const userLevel = AuthService.getLevel();
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<number | null>(null); // City filter

  // Fetch users and cities on component load
  useEffect(() => {
    fetchUsers();
    fetchCities();
  }, []);

  // Fetch users from API
  const fetchUsers = async () => {
    const userLevel = AuthService.getLevel();
    const userBranch = AuthService.getBranchId();
    const token = await AuthService.getAccessToken();
    if (userLevel !== "1") {
      setSelectedCity(parseInt(userBranch!));
    }
    try {
      const usersData = await userApiService.fetchUsers();
      if (Array.isArray(usersData)) {
        setUsers(usersData);
        if (selectedCity || userLevel !== "1") {
            var filterCity = selectedCity;
            if (userLevel != "1"){
                filterCity = parseInt(userBranch!);
            }
          const filtered = usersData.filter(
            (user) => user.branch_id === filterCity?.toString()
          );
          setFilteredUsers(filtered);
        } else {
          setFilteredUsers(usersData);
        }
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Fetch cities/branches from API
  const fetchCities = async () => {
    try {
      const citiesData = await cityApiService.fetchCities();
      if (Array.isArray(citiesData)) {
        setCities(citiesData);
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // Handle deleting a user
  const handleDeleteUser = async (id: number) => {
    try {
      await userApiService.deleteUser(id);
      fetchUsers(); // Refresh user list after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // Handle city filter change
  const handleCityChange = (event: SelectChangeEvent<number>) => {
    const cityId = event.target.value as number;
    setSelectedCity(cityId);

    if (cityId) {
      const filtered = users.filter(
        (user) => user.branch_id === cityId.toString()
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users); // Show all users if no city is selected
    }
  };

  // Helper function to map user levels to text
  const getLevelText = (level: string): string => {
    switch (level) {
      case "1":
        return "Adminisztrátor";
      case "2":
        return "Üzletvezető";
      case "3":
        return "Munkatárs";
      default:
        return "Ismeretlen szint";
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Felhasználók kezelése
      </Typography>

      {/* City Filter */}
      {userLevel == "1" && (
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: "20px" }}
        >
          <InputLabel>Kirendeltség</InputLabel>
          <Select
            value={selectedCity || ""}
            onChange={handleCityChange}
            label="Kirendeltség"
          >
            <MenuItem value="">
              <em>Összes kirendeltség</em>
            </MenuItem>
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* Add User Button */}
      {selectedCity && (
        <AddUserModal selectedCity={selectedCity} onSuccess={fetchUsers} />
      )}

      {/* Users Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Név</TableCell>
            <TableCell>Szint</TableCell>
            <TableCell>Kirendeltség</TableCell>
            <TableCell>Törlés</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>{getLevelText(user.level)}</TableCell>{" "}
              {/* Szöveges szint */}
              <TableCell>{user.branch_name}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteUser(user.id)}
                >
                  Törlés
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ManageUsers;
