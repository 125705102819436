import { jwtDecode } from 'jwt-decode';

const API_URL = 'https://franchise.slimstore.hu/api/login.php';
const REFRESH_URL = 'https://franchise.slimstore.hu/api/refresh.php';

interface LoginResponse {
    success: boolean;
    accessToken?: string;
    refreshToken?: string;
    message?: string;
}

interface JwtPayload {
    level?: string;
    branch_id?: string;
    branch_name?: string;
    sub?: string;
    exp?: number; // A JWT exp mezője, az időbélyeg lejárati idő
}

const login = async (username: string, password: string): Promise<LoginResponse> => {
    try {
        const response = await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        const data: LoginResponse = await response.json();

        if (data.success && data.accessToken && data.refreshToken) {
            // Tokenek mentése a localStorage-ba
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);

            // JWT token dekódolása
            const decodedToken = jwtDecode<JwtPayload>(data.accessToken);

            // A level mező kinyerése és elmentése, ha létezik
            if (decodedToken.level) {
                localStorage.setItem('level', decodedToken.level);
            }
            if (decodedToken.branch_id) {
                localStorage.setItem('branch_id', decodedToken.branch_id);
            }
            console.log(decodedToken);
            if (decodedToken.branch_name) {
                localStorage.setItem('branch_name', decodedToken.branch_name);
            }
            if (decodedToken.sub) {
                localStorage.setItem('userId', decodedToken.sub);
            }
        }

        return data;
    } catch (error) {
        // Hiba esetén visszaadunk egy alapértelmezett hibás választ
        return {
            success: false,
            message: 'Bejelentkezési hiba történt',
        };
    }
};

const refreshAccessToken = async (): Promise<string | null> => {
    const refreshToken = getRefreshToken();
    
    if (!refreshToken) {
        return null;
    }

    try {
        const response = await fetch(REFRESH_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"token": refreshToken }),
        });

        const data: LoginResponse = await response.json();
        if (data.accessToken) {
            localStorage.setItem('accessToken', data.accessToken);
            return data.accessToken;
        } else {
            AuthService.logout();
        }
        AuthService.logout();
        return null;
    } catch (error) {
        console.error('Hiba történt a token frissítése során:', error);
        AuthService.logout();
        return null;
    }
};

const isTokenExpired = (token: string): boolean => {
    try {
        const decodedToken = jwtDecode<JwtPayload>(token);
        if (!decodedToken.exp) {
            return true; // Ha nincs exp mező, tekintsük lejártnak
        }
        const currentTime = Math.floor(Date.now() / 1000);
        return decodedToken.exp < currentTime;
    } catch (error) {
        return true; // Hiba esetén tekintsük lejártnak
    }
};

const getAccessToken = async (): Promise<string | null> => {
    let accessToken = localStorage.getItem('accessToken');

    if (accessToken && isTokenExpired(accessToken)) {
        accessToken = await refreshAccessToken();
    }

    return accessToken;
};

const getRefreshToken = (): string | null => {
    return localStorage.getItem('refreshToken');
};

const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('level');
    localStorage.removeItem('branch_id');
    localStorage.removeItem('branch_name');
    localStorage.removeItem('userId');
};

const getLevel = (): string | null => {
    return localStorage.getItem('level');
};

const getUserId = (): string | null => {
    return localStorage.getItem('userId');
};

const getBranchId = (): string | null => {
    return localStorage.getItem('branch_id');
};

const getBranchName = (): string | null => {
    return localStorage.getItem('branch_name');
};


const AuthService = {
    login,
    logout,
    getAccessToken,
    getRefreshToken,
    getUserId,
    getLevel,
    getBranchId,
    getBranchName
};

export default AuthService;