import React, { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import axios from 'axios';
import { Box, Button, Drawer, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';

interface QRResult {
  rawValue: string;
}

const Attendes: React.FC = () => {
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');

  const handleRegister = async (email: string) => {
    try {
      const formData = new FormData();
      formData.append('email', email);
      const response = await axios.post('https://leltar.slimstore.hu/attendes.php', formData);
      setResponseMessage(response.data.message);
    } catch (error: any) {
      console.error('Hiba történt:', error.message);
      setResponseMessage('Valami hiba történt a regisztráció során.');
    }
    setIsScannerOpen(false);
  };

  const handleScan = (data: string) => {
    if (data) {
      try {
        const decodedData = atob(data);
        const jsonData = JSON.parse(decodedData);
        const email = jsonData.Email;
        handleRegister(email);
      } catch (error) {
        console.error('Hiba a QR kód feldolgozása során:', error);
        window.alert("Hibás QR kód formátum");
      }
    }
  };

  const handleError = (error: Error) => {
    console.error('QR kód hiba:', error);
    window.alert("Hibás QR kód");
  };

  const handleOpenScanner = () => {
    setIsScannerOpen(true);
  };

  const handleCloseModal = () => {
    setResponseMessage('');
    setIsScannerOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
      <Typography variant="h4" gutterBottom>
        Jelenléti regisztráció a sorsoláshoz
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpenScanner}>
        Regisztráció
      </Button>

            <Drawer anchor="bottom" open={isScannerOpen} onClose={() => setIsScannerOpen(false)}>
                <Box p={2}>
                  <Scanner onScan={(result) => handleScan(result[0].rawValue)} onError={(error) => console.error("QR Code error:", error)} />
                </Box>
              </Drawer>

              <Dialog open={!!responseMessage} onClose={handleCloseModal}>
        <DialogContent>
          <Typography>{responseMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Attendes;