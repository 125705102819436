import React, { useEffect, useState, useContext, FormEvent } from 'react';
import { Button, TextField, Select, MenuItem, Typography, CircularProgress, FormControl, InputLabel, Box, Grid, Dialog, DialogContent, Drawer, List, ListItem, ListItemText, SelectChangeEvent } from '@mui/material';
import jwt_decode from "jwt-decode";
import storageApiService from '../../services/api/storageApiService';
import AuthService from '../../services/AuthService';
import { ItemType, ItemTypeAdded, PostBody, ScrapBody } from '../models/itemAndLocation';
import inventoryApiService from "../../services/api/inventoryApiService";
// import Cookies from 'universal-cookie';
import { Scanner } from '@yudiel/react-qr-scanner';
// import { getLocationsAndItemTypes, postNewItemToStorage, postQRcode, getItemsFromStorage } from '../../Services/Api/StorageApi';
  
const ScrapItem: React.FC = () => {

  const [location, setLocation] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [itemList, setItemList] = useState<ItemType[]>([]);
  const [selectedItem, setSelectedItem] = useState<ItemType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    const data = await storageApiService.fetchItemTypes();
    setItemList(data);
     setLocation(AuthService.getBranchName() ?? "");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredItemList = itemList.filter(item => item.id < 8);

  // Kiválasztás kezelése
  const handleSelectChange = (event: SelectChangeEvent<ItemType>) => {
    const selectedId = event.target.value;
    const item = itemList.find((item) => item.itemName === selectedId);
    setSelectedItem(item);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
      if (selectedItem != null) {
        if(type === "") {
            alert("Kérlek válassz típust!")
            return
            }
        if (window.confirm("Biztosan felviszed ezt a csere/selejt/próbát? | " + location + " - " + type + selectedItem.itemName)) {
         setIsLoading(true)
        var today = new Date(), stringDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        inventoryApiService.decrementInventoryQuantity(AuthService.getBranchId()!, selectedItem.id.toString(), "-");
        var body: ScrapBody = {
            time: stringDate,
            location: location,
            scrapType: type,
            userid: parseInt(AuthService.getUserId()!),
            item: selectedItem.itemName
        } 
        storageApiService.postScrapItemToStorage(
          body
        );
       }
       
      } else {
        alert("Válassz ki legalább 1 terméket!")
      }
      
    }
    const handleScrapTypeChange = (newType: string) => {
        setType(newType);
      };
    return (
        <Box p={3} sx={{ maxWidth: 600, mx: 'auto', backgroundColor: '#fff', borderRadius: 2, boxShadow: 3 }}>
          <Typography variant="h4" color="primary" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Új csere/selejt/próba felvitele
          </Typography>
          <Grid container spacing={3} direction="column">
            
            
            <Grid item>
              <TextField
                label="Helyszín"
                value={location}
                disabled
                fullWidth
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 3,
                  }
                }}
              />
            </Grid>
            <Grid item>
            <Grid item>
      <Typography variant="h6">Válassz egy elemet:</Typography>
      <Select
        value={selectedItem}
        onChange={handleSelectChange}
        displayEmpty
        fullWidth
      >
        <MenuItem value="">
          <em>Válassz egy elemet</em>
        </MenuItem>
        {filteredItemList.map((item) => (
          <MenuItem key={item.id} value={item.itemName}>
            {item.itemName}
          </MenuItem>
        ))}
      </Select>
    </Grid>
              </Grid>
<Grid item>
          <Box display="flex" justifyContent="center" gap={2}>
            <Button
              variant={type === "Csere" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleScrapTypeChange("Csere")}
            >
              Csere
            </Button>
            <Button
              variant={type === "Selejt" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleScrapTypeChange("Selejt")}
            >
              Selejt
            </Button>
            <Button
              variant={type === "Próba" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleScrapTypeChange("Próba")}
            >
              Próba
            </Button>
          </Box>
        </Grid>
    
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={isLoading}
                sx={{ borderRadius: 3, fontWeight: 'bold' }}
              >
                Adat felvitele
              </Button>
              {isLoading && <CircularProgress sx={{ color: "green", mt: 2 }} />}
            </Grid>
          </Grid>
        </Box>
      );
};

export default ScrapItem;
