import React, { useState, useEffect } from "react";
import { City } from "../models/city";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import cityApiService from "../../services/api/cityApiService";
import AddBranchModal from "./AddLocationModal";

const ManagePartnerCities: React.FC = () => {
  const [filteredCities, setFilteredCities] = useState<City[]>([]); // Szűrt kirendeltségek
  const [cities, setCities] = useState<City[]>([]); // Eredeti kirendeltségek
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null); // Kiválasztott város
  const [uniqueLocations, setUniqueLocations] = useState<string[]>([]); // Egyedi location értékek

  // Kirendeltségek betöltése komponens betöltésekor
  useEffect(() => {
    fetchCities();
  }, []);

  // Kirendeltségek lekérése API-ból
  const fetchCities = async () => {
    try {
      const citiesData = await cityApiService.fetchCities();
      if (Array.isArray(citiesData)) {
        setCities(citiesData);
        setFilteredCities(citiesData); // Alapértelmezés szerint az összes kirendeltség látható
        const uniqueLocationsSet = new Set(citiesData.map(city => city.location));
        setUniqueLocations(Array.from(uniqueLocationsSet)); // Egyedi városok listája

        if(selectedLocation){
          const filtered = citiesData.filter((city) => city.location === selectedLocation);
          setFilteredCities(filtered);
          }
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // Város törlése
  const handleDeleteCity = async (id: number) => {
    try {
      await cityApiService.deleteCity(id);
      fetchCities(); // Frissítés törlés után
    } catch (error) {
      console.error("Error deleting city:", error);
    }
  };

  // Város szűrő eseménykezelő
  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    const selectedLocation = event.target.value;
    setSelectedLocation(selectedLocation);

    // Szűrés a kiválasztott város alapján
    if (selectedLocation) {
      const filtered = cities.filter(
        (city) => city.location === selectedLocation
      );
      setFilteredCities(filtered); // Szűrt lista frissítése
    } else {
      setFilteredCities(cities); // Ha nincs szűrő kiválasztva, az összes kirendeltség látható
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Kirendeltségek kezelése
      </Typography>

      {/* Város szűrő */}
      <FormControl
        fullWidth
        variant="outlined"
        style={{ marginBottom: "20px" }}
      >
        <InputLabel>Város</InputLabel>
        <Select
          value={selectedLocation || ""}
          onChange={handleLocationChange}
          label="Város"
        >
          <MenuItem value="">
            <em>Összes város</em>
          </MenuItem>
          {uniqueLocations.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Új kirendeltség hozzáadása */}
      {selectedLocation && (
        <AddBranchModal selectedLocation={selectedLocation} onSuccess={fetchCities} />
      )}

      {/* Kirendeltségek táblázata */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Név</TableCell>
            <TableCell>Város</TableCell>
            <TableCell>Törlés</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCities.map((branch) => (
            <TableRow key={branch.id}>
              <TableCell>{branch.id}</TableCell>
              <TableCell>{branch.name}</TableCell>
              <TableCell>{branch.location}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteCity(branch.id)}
                >
                  Törlés
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ManagePartnerCities;