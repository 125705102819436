import { User, CreateUser } from "../../components/models/users";
import { networkManager } from "../NetworkManager";
import { USERS_API_URL } from "../../constants";

// Fetch all users
const fetchUsers = async (): Promise<User[]> => {
  const data = await networkManager.get<{ users: User[] }>(USERS_API_URL);
  return data.users;
};

// Add a new user
const addUser = async (user: Omit<CreateUser, 'id'>): Promise<void> => {
  await networkManager.post(USERS_API_URL, user);
};

// Update an existing user
const updateUser = async (user: User): Promise<void> => {
  await networkManager.put(USERS_API_URL, user);
};

// Delete a user
const deleteUser = async (userId: number): Promise<void> => {
  await networkManager.delete(`${USERS_API_URL}?id=${userId}`);
};

// Export all API functions as a service
const userApiService = {
  fetchUsers,
  addUser,
  updateUser,
  deleteUser,
};

export default userApiService;