import React from 'react';
import './Login.css';
import LoginForm from './LoginForm';

const Login: React.FC = () => {
    return (
        <div className="login-container">
            <img style={{width: "150px", marginBottom: "30px"}}  src='https://franchise.slimstore.hu/assets/franchise_logo.png'></img>
            <LoginForm />
        </div>
    );
}

export default Login;