import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import AuthService from "./AuthService";
import { API_BASE_URL } from '../constants';

class NetworkManager {
  api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({ baseURL });

    this.api.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const accessToken = await AuthService.getAccessToken();
        if (accessToken) {
          config.headers = config.headers || {};
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  async get<T>(url: string): Promise<T> {
    const response = await this.api.get<T>(url);
    return response.data;
  }

  async post<T>(url: string, data: T): Promise<void> {
    await this.api.post(url, data);
  }

  async put<T>(url: string, data: T): Promise<void> {
    await this.api.put(url, data);
  }

  async delete(url: string): Promise<void> {
    await this.api.delete(url);
  }
}


export const networkManager = new NetworkManager(API_BASE_URL);