import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard'; // Dashboard component
import './App.css';
import MessageApp from './components/Messages/Messages';
import AddSoldItem from './components/SoldItems/AddSoldItem';
import ItemListScreen from './components/SoldItems/ListSoldItems';
import Attendes from './components/Contest/AddAttendes';
import RafflePage from './components/Contest/Winner';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/messages" element={<MessageApp />} />
          <Route path="/add-item" element={<AddSoldItem />} />
          <Route path="/sales" element={<ItemListScreen />} />
          <Route path="/add-attendees" element={<Attendes />} />
          <Route path="/sorsolas" element={<RafflePage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;