import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Divider,
  Paper,
  ListItemText,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import AuthService from '../../services/AuthService';

interface User {
  id: number;
  username: string;
  level: number;
  branch_id: number;
}

interface Message {
  id: number;
  sender_id: number;
  receiver_id: number;
  message_text: string;
  timestamp: string;
  is_read: boolean; // Track read status
}

const MessageApp = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [threads, setThreads] = useState<{ user: User; lastMessage: string; unreadCount: number }[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [messageText, setMessageText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false); // State to handle new message

  const userId = AuthService.getUserId(); // Get current user ID
  const userLevel = AuthService.getLevel(); // Get current user level
  const userBranch = AuthService.getBranchId(); // Get current user branch

  useEffect(() => {
    fetchUsers();
    fetchThreads(); // Load initial threads (conversations)
  }, []);

  // Fetch users
  const fetchUsers = async () => {
    const token = await AuthService.getAccessToken();
    try {
      const response = await fetch('https://franchise.slimstore.hu/api/users.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUsers(
        data.users.filter((user: { id: string; branch_id: string; level: string }) => {
          const currentUserLevel = AuthService.getLevel();
          const currentUserBranch = AuthService.getBranchId();
          if (currentUserLevel === "1") {
            return user.id !== userId;
          } else if (currentUserLevel === "2") {
            return user.id !== userId && (user.level === "1" || user.branch_id === currentUserBranch);
          } else if (currentUserLevel === "3") {
            return user.branch_id === currentUserBranch && user.id !== userId;
          }
          return false;
        }) || []
      );
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fetch threads (distinct conversations)
  const fetchThreads = async () => {
    const token = await AuthService.getAccessToken();
    try {
      const response = await fetch('https://franchise.slimstore.hu/api/messages/threads.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setThreads(data.threads || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching threads:', error);
      setLoading(false);
    }
  };

  // Fetch conversation between the current user and the selected user
  const fetchConversation = async (userId: number, otherUserId: number) => {
    const token = await AuthService.getAccessToken();
    try {
      const response = await fetch(
        `https://franchise.slimstore.hu/api/messages/conversation.php?user1=${userId}&user2=${otherUserId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setConversation(data.messages || []);
      
      // Mark unread messages as read
      markMessagesAsRead(userId, otherUserId);
    } catch (error) {
      console.error('Error fetching conversation:', error);
    }
  };

  // Mark unread messages as read
  const markMessagesAsRead = async (userId: number, otherUserId: number) => {
    const token = await AuthService.getAccessToken();
    try {
      await fetch(`https://franchise.slimstore.hu/api/messages/mark_read.php?user1=${userId}&user2=${otherUserId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  // Send message to selected user and update conversation
  const sendMessage = async () => {
    if (!selectedUser) return;

    const token = await AuthService.getAccessToken();
    setSending(true);
    const data = {
      receiver_id: selectedUser.id,
      message_text: messageText,
    };

    try {
      const response = await fetch('https://franchise.slimstore.hu/api/messages/messages.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result["message"] === "Message sent successfully") {
        setMessageText(''); // Clear the input field after sending
        fetchConversation(parseInt(userId!), selectedUser.id); // Refresh the conversation
      } else {
        console.error('Failed to send message:', result.message);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };

  // Refresh conversation every 15 seconds
  useEffect(() => {
    if (selectedUser) {
      const intervalId = setInterval(() => {
        fetchConversation(parseInt(userId!), selectedUser.id);
        fetchThreads();
      }, 5000);

      return () => clearInterval(intervalId); 
    }
  }, [selectedUser, userId]);

  // When a user clicks on a thread, fetch the conversation
  const handleThreadClick = (user: User) => {
    setIsNewMessage(false); // Exit "New Message" mode
    setSelectedUser(user);
    fetchConversation(parseInt(userId!), user.id);
  };

  // Start new message
  const handleNewMessage = () => {
    setIsNewMessage(true); // Enable "New Message" mode
    setSelectedUser(null); // Clear selected user
    setConversation([]); // Clear conversation
  };

  // Chat bubble styling based on message sender and read status
  const renderMessageBubble = (message: Message) => {
    const isCurrentUser = message.sender_id === parseInt(userId!);
    const isUnread = !message.is_read && !isCurrentUser; // Unread if not sent by current user

    return (
      <Box
        key={message.id}
        sx={{
          display: 'flex',
          justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
          marginBottom: '10px',
        }}
      >
        <Paper
          sx={{
            padding: '10px',
            maxWidth: '60%',
            backgroundColor: isCurrentUser ? '#1976d2' : '#f0f0f0',
            color: isCurrentUser ? '#fff' : '#000',
            borderRadius: '10px',
          }}
        >
          <Typography variant="body1" fontWeight={isUnread ? 'bold' : 'normal'}>
            {message.message_text}
          </Typography>
          <Typography variant="caption" sx={{ display: 'block', marginTop: '5px', textAlign: isCurrentUser ? "right" : "left", color: isCurrentUser ? '#b3d4fc' : '#999' }}>
            {new Date(message.timestamp).toLocaleTimeString()}
          </Typography>
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100vh' }}>
      {/* Thread List (Sidebar) */}
      <Box sx={{ width: '30%', padding: '20px', borderRight: '1px solid #ccc' }}>
        <Typography variant="h6" gutterBottom>
          Beszélgetések
        </Typography>
        <Button variant="contained" sx={{ marginBottom: '10px' }} onClick={handleNewMessage}>
          Új üzenet
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
            <List>
            {threads.map((thread, index) => (
              <ListItem button key={index} onClick={() => handleThreadClick(thread.user)}>
                <ListItemText
                  primary={thread.user.username}
                  secondary={`${thread.lastMessage}`}
                />
                {thread.unreadCount > 0 && (
                  <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
                    {thread.unreadCount} új
                  </Typography>
                )}
              </ListItem>
            ))}
          </List>
        )}
      </Box>
  {/* Conversation View */}
  <Box sx={{ width: '70%', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
    {isNewMessage ? (
      <>
        {/* New message mode - select a recipient */}
        <FormControl fullWidth>
          <InputLabel id="select-user-label">Címzett</InputLabel>
          <Select
            labelId="select-user-label"
            value={selectedUser ? selectedUser.id : ''}
            onChange={(e) => setSelectedUser(users.find(user => user.id === e.target.value) || null)}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider sx={{ marginY: 2 }} />
        <TextField
          fullWidth
          label="Írd be az üzeneted"
          multiline
          rows={2}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          disabled={!selectedUser} // Disable message input until a recipient is selected
        />
        <Button
          variant="contained"
          sx={{ marginTop: 2 }}
          onClick={sendMessage}
          disabled={sending || messageText.trim() === '' || !selectedUser}
        >
          {sending ? <CircularProgress size={24} /> : 'Küldés'}
        </Button>
      </>
    ) : selectedUser ? (
      <>
        <Typography variant="h6" gutterBottom>
          {selectedUser.username}
        </Typography>
        <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingRight: '10px' }}>
          {conversation.map((message) => renderMessageBubble(message))}
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <TextField
          fullWidth
          label="Írd be az üzeneted"
          multiline
          rows={2}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ marginTop: 2 }}
          onClick={sendMessage}
          disabled={sending || messageText.trim() === ''}
        >
          {sending ? <CircularProgress size={24} /> : 'Küldés'}
        </Button>
      </>
    ) : (
      <Typography variant="h6" gutterBottom>
        Válaszd ki a beszélgetést
      </Typography>
    )}
  </Box>
</Box>
);
};
export default MessageApp;