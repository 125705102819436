import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material';
import storageApiService from '../../services/api/storageApiService';
import { ItemResponse } from '../models/itemResponseModel';
import ProductCard from './ProductCard';

const ItemListScreen: React.FC = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [items, setItems] = useState<ItemResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const location = "Szombathely";

  const fetchItems = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = { start_date: startDate, end_date: endDate, location };
      const response = await storageApiService.getSalesData(params);
      setItems(response);
    } catch (err) {
      console.error("Error fetching items:", err);
      setError("Hiba történt az adatok lekérésekor.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "startDate") setStartDate(value);
    if (name === "endDate") setEndDate(value);
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(today);
    setEndDate(today);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchItems();
    }
  }, [startDate, endDate]);

  return (
    <Box p={3} sx={{ width: 'auto', minHeight: '100vh' }}>
      <Typography variant="h4" color="primary" align="center" gutterBottom>
        Eladások
      </Typography>
      
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Kezdő dátum"
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Befejező dátum"
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        
        {isLoading && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <CircularProgress sx={{ mt: 2 }} />
          </Grid>
        )}
        
        {error && (
          <Grid item xs={12}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}

        <Grid container justifyContent="center"
      alignItems="center" spacing={2} sx={{ mt: 0 }}>
        {items.length > 0 ? (
            items.map((item) => (
              <Grid item >
        <ProductCard item={item} />
      </Grid>
            ))
           ) : (
            !isLoading && (
              <Grid item xs={12}>
                <Typography align="center" sx={{ mt: 2 }}>
                  Nincs találat a megadott időszakra.
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemListScreen;