import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ProductCard: React.FC<{ item: any }> = ({ item }) => {
  return (
    <Box
      sx={{
        p: 2,
        border: '2px solid #4CAF50', // Green border to match the example
        borderRadius: 2,
        maxWidth: 300,
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Location and Date */}
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item>
          <LocationOnIcon sx={{ color: '#4CAF50' }} />
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ color: '#4CAF50', fontWeight: 'bold' }}>
            {item.location}
          </Typography>
        </Grid>
      </Grid>
      
      <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{ mt: 1 }}>
        <Grid item>
          <CalendarTodayIcon sx={{ color: '#4CAF50' }} />
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            {new Date(item.time).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 1, borderColor: '#4CAF50' }} />

      {/* Price and Payment Type */}
      <Box
        sx={{
          display: 'inline-block',
          px: 2,
          py: 0.5,
          border: '1px solid #000',
          borderRadius: '16px',
          textAlign: 'center',
          mt: 1,
          mb: 2,
        }}
      >
        <Typography variant="body2">
          {item.price} Ft - {item.paymentType}
        </Typography>
      </Box>

      {/* Product Details */}
      {item.items && (
        <Grid container spacing={2} justifyContent="center">
          {JSON.parse(item.items).map((product: any, idx: number) => (
            <Grid item key={idx}>
              <Box
                sx={{
                  px: 2,
                  py: 0.5,
                  backgroundColor: '#000000',
                  color: '#ffffff',
                  borderRadius: '16px',
                  display: 'inline-block',
                }}
              >
                <Typography variant="body2">
                  {product.itemType} - {product.itemPrice} Ft
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Slimmer Email */}
      {item.slimmerEmail && (
        <Typography
          variant="caption"
          align="center"
          display="block"
          sx={{
            mt: 2,
            color: '#4a4a4a',
            fontStyle: 'italic',
          }}
        >
          {item.slimmerEmail}
        </Typography>
      )}
    </Box>
  );
};

export default ProductCard;