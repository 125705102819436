import { networkManager } from "../NetworkManager";
import { LOCATIONS_API_URL, QR_API_URL, SCRAP_API_URL, STORAGE_API_URL } from "../../constants";
import { ItemType, PostBody, GetSalesDataParams, ScrapBody } from "../../components/models/itemAndLocation";
import axios from "axios";
import { ItemResponse } from "../../components/models/itemResponseModel";

// Fetch all users
const fetchItemTypes = async (): Promise<ItemType[]> => {
  const data = await axios.get<{ itemTypes: ItemType[] }>(LOCATIONS_API_URL);
  console.log(data);
  return data.data.itemTypes;
};

const postScrapItemToStorage = async (body: ScrapBody) => {
  try {
    await networkManager.post(SCRAP_API_URL, body);
    alert(body.scrapType + " rögzítve!");
    window.location.reload();
  } catch (error) {
    console.error("Error:", error);
    alert("Hiba történt az eladás rögzítésekor.");
  }
};
const postNewItemToStorage = async (body: PostBody) => {
    try {
      await networkManager.post(STORAGE_API_URL, body);
      alert("Eladás rögzítve!");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      alert("Hiba történt az eladás rögzítésekor.");
    }
  };

  const postQrCode = async (body: { qrstring: string; email: string; }) => {
    try {
      await networkManager.post(QR_API_URL, body);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      alert("Hiba történt az eladás rögzítésekor.");
    }
  };
  
  const getSalesData = async (params: GetSalesDataParams): Promise<ItemResponse[]> => {
    const { start_date, end_date, location } = params;
    const url = new URL(STORAGE_API_URL);
    url.searchParams.append("start_date", start_date);
    url.searchParams.append("end_date", end_date);
    if (location) {
      url.searchParams.append("location", location);
    }
  
    try {
      const response = await networkManager.get<{ success: boolean; data: ItemResponse[] }>(url.toString());
      if (response && response.success) {
        return response.data;
      } else {
        alert("Nem sikerült az eladási adatokat lekérni.");
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Hiba történt az eladási adatok lekérésekor.");
      return [];
    }
  };
// Export all API functions as a service
const StorageApiService = {
    fetchItemTypes,
    postNewItemToStorage,
    postQrCode,
    getSalesData,
    postScrapItemToStorage
};

export default StorageApiService;