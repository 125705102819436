import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import userApiService from '../../services/api/userApiService';

interface AddUserModalProps {
  selectedCity: number;
  onSuccess: () => void; // Callback függvény a sikeres felhasználó hozzáadáshoz
}

const AddUserModal: React.FC<AddUserModalProps> = ({ selectedCity, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [level, setLevel] = useState(1); // Alapértelmezett: Adminisztrátor
  const [password, setPassword] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddUser = async () => {
    try {
      // Új felhasználó objektum létrehozása
      const newUser = {
        username,
        level,
        password: password,
        branch_id: selectedCity,
      };

      // API hívás a felhasználó hozzáadásához
      await userApiService.addUser(newUser);
      setUsername('');
      setLevel(1);
      setPassword('');
      // Modal bezárása
      handleClose();
      onSuccess();
    } catch (error) {
      console.error('Hiba történt a felhasználó hozzáadása során:', error);
    }
  };

  return (
    <>
      {/* Felhasználó hozzáadása gomb */}
      {selectedCity && (
        <Button variant="contained" color="secondary" onClick={handleOpen}>
          Felhasználó hozzáadása
        </Button>
      )}

      {/* Modal ablak */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Új felhasználó hozzáadása</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Felhasználónév"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          
          {/* Legördülő menü a felhasználói szintekhez */}
          <FormControl fullWidth margin="dense">
            <InputLabel id="user-level-label">Felhasználói szint</InputLabel>
            <Select
              labelId="user-level-label"
              value={level}
              onChange={(e) => setLevel(Number(e.target.value))}
              label="Felhasználói szint"
            >
              <MenuItem value={2}>Üzletvezető</MenuItem>
              <MenuItem value={3}>Munkatárs</MenuItem>
            </Select>
          </FormControl>
          
          <TextField
            margin="dense"
            label="Jelszó"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword((e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Mégse
          </Button>
          <Button onClick={handleAddUser} color="primary">
            Hozzáadás
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddUserModal;