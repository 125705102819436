import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';

const LoginForm: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await AuthService.login(username, password);
            if (response.success) {
                // Bejelentkezés sikeres, irányítás a dashboard-ra
                navigate('/dashboard');
            } else {
                // Hibás felhasználónév vagy jelszó
                setErrorMessage('Hibás felhasználónév vagy jelszó');
            }
        } catch (error) {
            // Hiba a bejelentkezés során
            setErrorMessage('Hiba a bejelentkezés során');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
                <label>Felhasználónév</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Jelszó</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button type="submit">Bejelentkezés</button>
        </form>
    );
}

export default LoginForm;