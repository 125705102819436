import { City } from "../../components/models/city";
import { networkManager } from "../NetworkManager";
import { CITIES_API_URL } from "../../constants";

// Fetch all cities/branches
const fetchCities = async (): Promise<City[]> => {
  const data = await networkManager.get<{ cities: City[] }>(CITIES_API_URL);
  return data.cities;
};

// Add a new city
const addCity = async (city: Omit<City, 'id'>): Promise<void> => {
  await networkManager.post(CITIES_API_URL, city);
};

// Update an existing city
const updateCity = async (city: City): Promise<void> => {
  await networkManager.put(CITIES_API_URL, city);
};

// Delete a city
const deleteCity = async (cityId: number): Promise<void> => {
  await networkManager.delete(`${CITIES_API_URL}?id=${cityId}`);
};

const cityApiService = {
  fetchCities,
  addCity,
  updateCity,
  deleteCity,
};

export default cityApiService;