import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  Box,
} from "@mui/material";
import { Scanner } from "@yudiel/react-qr-scanner";
import inventoryApiService from "../../services/api/inventoryApiService";
import { Inventory } from "../models/inventoryModel";
import AuthService from "../../services/AuthService";

export default function FranchiseRaktarkeszlet() {
  const [selectedLocation, setSelectedLocation] = useState("7");
  const [inventory, setInventory] = useState<Inventory[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showScanner, setShowScanner] = useState(false);

  useEffect(() => {
    setShowScanner(false);
    const loadInventory = async () => {
        const inventoryData = await inventoryApiService.fetchInventoryById(AuthService.getBranchId()!);
        setInventory(inventoryData);
    };
    loadInventory();
  }, [selectedLocation]);

  const handleSubmitQR = async () => {
    // if (productData && neededQuantity > 0) {
    //   await updateInventory(selectedLocation, [
    //     {
    //       item_id: productData.item_id,
    //       subtype: productData.phonetype,
    //       quantity: neededQuantity,
    //     },
    //   ]);

    //   setIsPopupOpen(false);
    //   const inventoryData = await fetchInventoryByBranch(selectedLocation);
    //   setInventory(inventoryData);
    //   setShowScanner(true);
    // } else {
    //   alert("A készlet már rendben van, nincs szükség feltöltésre.");
    // }
  };

  const handleScan = async (data: string) => {
    console.log(data);
    if (data) {
      const [productName, subtype] = data.includes("-") ? data.split(" - ") : [data, ""];
      const foundProduct = inventory.find(
        (item) =>
          item.item_name === productName &&
          (subtype === "" || item.phonetype === subtype)
      );
  
      if (foundProduct) {
        try {
          await inventoryApiService.moveInTransitToQuantity(AuthService.getBranchId()!, foundProduct.item_id, foundProduct.phonetype);
  
          // Fetch updated product from the server (optional, depending on API capabilities)
          const updatedProduct = {
            ...foundProduct,
            quantity: foundProduct.quantity + foundProduct.in_transit,
            in_transit: 0,
          };
  
          // Update inventory with the new values
          setInventory((prevInventory) =>
            prevInventory.map((item) =>
              item.item_id === updatedProduct.item_id && item.phonetype === updatedProduct.phonetype
                ? updatedProduct
                : item
            )
          );
  
          setShowScanner(false);
        } catch (error) {
          console.error("Error updating inventory:", error);
          alert("Hiba történt a termék frissítése során.");
        }
      } else {
        alert("Termék nem található a listában.");
      }
    }
  };

  return (
    <div className="containerok">
      <Typography variant="h4" gutterBottom>
        Franchise raktárkészlet kezelés
      </Typography>
      <Button variant="contained" onClick={() => setShowScanner((prev) => !prev)}>
        {showScanner ? "QR-kód olvasó elrejtése" : "QR-kód olvasó"}
      </Button>
      <Drawer anchor="bottom" open={showScanner} onClose={() => setShowScanner(false)}>
        <Box p={2}>
            <Scanner onScan={(result) => handleScan(result[0].rawValue)} onError={(error) => window.alert("Hibás QR kód")} />
        </Box>
      </Drawer>

      {inventory.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Termék</TableCell>
                <TableCell>Típus</TableCell>
                <TableCell>Készlet</TableCell>
                <TableCell>Úton lévő készlet</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventory.map((item) => {
                const missingQuantity = item.required_qty - item.quantity;
                const cellStyle = {
                  backgroundColor: missingQuantity > 0 ? "rgba(255, 99, 132, 0.2)" : "rgba(75, 192, 192, 0.2)",
                  color: missingQuantity > 0 ? "red" : "green",
                };

                return (
                  <TableRow key={`${item.item_id}-${item.phonetype}`}>
                    <TableCell>{item.item_name}</TableCell>
                    <TableCell>{item.phonetype !== "-" ? item.phonetype : ""}</TableCell>
                    <TableCell style={cellStyle}>
                      {item.quantity} db ({missingQuantity > 0 ? `${missingQuantity} db hiányzik` : "Készlet rendben"})
                    </TableCell>
                    <TableCell>{`${item.in_transit} db úton`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}