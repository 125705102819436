import React, { useState } from "react";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import ManagePartnerCities from "../AdminManagement/ManagePartnerCities";
import ManageUsers from "../AdminManagement/ManageUsers";
import MenuIcon from "@mui/icons-material/Menu";
import LocationCityIcon from "@mui/icons-material/LocationCity"; // City icon
import PeopleIcon from "@mui/icons-material/People";
import SalesIcon from "@mui/icons-material/LocalMall"; // Sales icon
import AddIcon from "@mui/icons-material/Add";
import Chat from "@mui/icons-material/Chat";
import Logout from "@mui/icons-material/Logout";
import "./Dashboard.css";
import AuthService from "../../services/AuthService";
import MessageApp from "../Messages/Messages";
import NewItem from "../SoldItems/AddSoldItem";
import ItemListScreen from "../SoldItems/ListSoldItems";
import { DeleteTwoTone, Inventory, Person } from "@mui/icons-material";
import FranchiseRaktarkeszlet from "../Inventory/InventoryList";
import ScrapItem from "../SoldItems/ScrapItem";

const Dashboard: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const level = AuthService.getLevel();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    AuthService.logout();
    navigate("/login");
  };
  return (
    <div className="dashboard-container">
      {/* Mobile Hamburger icon */}
      <div className="mobile-header">
        <button className="menu-btn" style={{width: "50px"}} onClick={toggleSidebar}>
          <MenuIcon />
        </button>
        <img style={{
    filter: "invert(100%)", width: "100px", marginRight: "15px"
  }}  src='https://franchise.slimstore.hu/assets/franchise_logo.png'></img>
      </div>

      {/* Sidebar */}
      <aside className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <img style={{
    filter: "invert(100%)", 
    ...(sidebarOpen ? {width: "50px"} : {width: "100px"})
  }}  src='https://franchise.slimstore.hu/assets/franchise_logo.png'></img>
        <div className="sidebar-header">
          <button className="menu-btn" onClick={toggleSidebar}>
            <MenuIcon />
          </button>
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/dashboard/add-item">
                <AddIcon className="menu-icon" />
                {!sidebarOpen && <span className="menuButton">Eladás felvitel</span>}
              </Link>
            </li>
            <li>
              <Link to="/dashboard/scrap-item">
                <DeleteTwoTone className="menu-icon" />
                {!sidebarOpen && <span className="menuButton">Csere/Selejt/Próba felvitel</span>}
              </Link>
            </li>
            <li>
              <Link to="/dashboard/sales">
                <SalesIcon className="menu-icon" />
                {!sidebarOpen && <span className="menuButton">Eladások</span>}
              </Link>
            </li>
            <li>
              <Link to="/dashboard/inventory">
                <Inventory className="menu-icon" />
                {!sidebarOpen && <span className="menuButton">Raktárkészlet</span>}
              </Link>
            </li>
            {level == "1" && (<li>
              <Link to="/dashboard/manage-cities">
                <LocationCityIcon className="menu-icon" />
                {!sidebarOpen && (
                  <span className="menuButton">Kirendeltségek kezelése</span>
                )}
              </Link>
            </li>)}
            {(level == "1" || level == "2") &&(
              <li>
              <Link to="/dashboard/manage-users">
                <PeopleIcon className="menu-icon" />
                {!sidebarOpen && (
                  <span className="menuButton">Felhasználók kezelése</span>
                )}
              </Link>
            </li>
          )}
            <li>
              <Link to="/dashboard/messages">
                <Chat className="menu-icon" />
                {!sidebarOpen && <span className="menuButton">Chat</span>}
              </Link>
            </li>
            <li className="logout" onClick={handleLogout}>
              <Logout className="menu-icon" />
              {!sidebarOpen && (
                <span className="menuButton">Kijelentkezés</span>
              )}
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Content */}
      <main className={`content ${sidebarOpen ? "shifted" : ""}`}>
        <Routes>
          <Route path="manage-cities" element={<ManagePartnerCities />} />
          <Route path="manage-users" element={<ManageUsers />} />
          <Route path="messages" element={<MessageApp />} />
          <Route path="add-item" element={<NewItem />} />
          <Route path="scrap-item" element={<ScrapItem />} />
          <Route path="sales" element={<ItemListScreen />} />
          <Route path="inventory" element={<FranchiseRaktarkeszlet />} />
          <Route path="/" element={<h2>Üdvözöllek az Slim Franchise portálon!</h2>} />
        </Routes>
      </main>
    </div>
  );
};

export default Dashboard;
