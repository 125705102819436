import React, { useEffect, useState } from 'react';
import { Button, Card, CardMedia, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

interface Prize {
  id: number;
  name: string;
  quantity: number;
  imgurl: string;
}

interface Coupon {
  tombolacoupon: string;
  email: string;
}

const RafflePage: React.FC = () => {
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [selectedPrize, setSelectedPrize] = useState<Prize | null>(null);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [currentCoupon, setCurrentCoupon] = useState<string>('');
  const [isRaffling, setIsRaffling] = useState(false);

  useEffect(() => {
    axios.get<Prize[]>('https://franchise.slimstore.hu/api/prizes.php').then((response) => {
      setPrizes(response.data);
    });
  }, []);

  const handlePrizeSelect = (prize: Prize) => {
    setSelectedPrize(prize);
  };

  const startRaffle = () => {
    if (!selectedPrize) return;

    setIsRaffling(true);
    axios.get<Coupon[]>('https://leltar.slimstore.hu/attendes.php')
      .then((response) => {
        setCoupons(response.data);
        let index = 0;
        let currentCouponOk = '';
        let currentEmail = '';
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * response.data.length);
            setCurrentCoupon(response.data[randomIndex].tombolacoupon);
            currentCouponOk = response.data[randomIndex].tombolacoupon;
            currentEmail = response.data[randomIndex].email;
          }, 20);

        const stopRaffle = () => {
          clearInterval(interval);
          setIsRaffling(false);
          if (selectedPrize && selectedPrize.id < 4) {
            axios.post('https://franchise.slimstore.hu/api/raffle-winner.php', { prize_id: selectedPrize.id, tombolacoupon: currentCouponOk })
              .then(() => {
                axios.patch('https://leltar.slimstore.hu/attendes.php', { email: currentEmail })
                  .then(() => {
                    alert(`Nyertes: ${currentCouponOk}`);
                    window.location.reload();
                  })
                  .catch(() => alert('Hiba történt a nyertes elküldésekor'));
              })
              .catch(() => alert('Hiba történt az email törlésekor'));
          } else {
            // Ha az ID nagyobb vagy egyenlő, mint 4, csak a tombolacoupont küldjük el
            axios.post('https://franchise.slimstore.hu/api/raffle-winner.php', { prize_id: selectedPrize.id, tombolacoupon: currentCouponOk })
              .then(() => {
                alert(`Nyertes: ${currentCouponOk}`);
                window.location.reload();
              })
              .catch(() => alert('Hiba történt a nyertes elküldésekor'));
          }
        };

        document.getElementById('stopRaffleBtn')?.addEventListener('click', stopRaffle);
      })
      .catch(() => alert('Kuponok lekérése sikertelen'));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
      <Typography variant="h4" align="center">Nyeremény sorsolás</Typography>
      
      <Box display="flex" justifyContent="center" gap={2} flexWrap="wrap" mt={4}>
        {prizes.map((prize) => (
          <Card
            key={prize.id}
            onClick={() => handlePrizeSelect(prize)}
            sx={{
              cursor: 'pointer',
              width: 200,
              border: selectedPrize?.id === prize.id ? '2px solid #3f51b5' : '1px solid #ddd',
              boxShadow: selectedPrize?.id === prize.id ? '0px 0px 10px rgba(0, 0, 255, 0.5)' : 'none',
              transition: 'border 0.3s, box-shadow 0.3s',
            }}
          >
            <CardMedia component="img" height="140" image={prize.imgurl} alt={prize.name} sx={{ objectFit: "contain" }}/>
            <Box p={2} textAlign="center">
              <Typography variant="h6">{prize.name}</Typography>
              <Typography variant="body2">Mennyiség: {prize.quantity}</Typography>
            </Box>
          </Card>
        ))}
      </Box>

      {selectedPrize && (
        <Box mt={4} textAlign="center">
          <Typography variant="h5">Nyeremény: {selectedPrize.name}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={startRaffle}
            disabled={isRaffling}
            sx={{ mt: 2 }}
          >
            Sorsolás
          </Button>
        </Box>
      )}

      {isRaffling && (
        <Box mt={4} textAlign="center">
          <Typography variant="h2">{currentCoupon}</Typography>
          <Button id="stopRaffleBtn" variant="contained" color="secondary" sx={{ mt: 2 }}>
            Stop
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RafflePage;